import Vue from 'vue'
import Vuex from 'vuex'
import Mode from "../../../client-spa/src/components/Modes";
import {vueInstance} from '../main'

Vue.use(Vuex)

import Modes from '../components/Modes'


export default new Vuex.Store({
  state: {
      principal: {
          userName: '',
          activeDB: '',
          role: '',
          startDate: '',
          finishDate: '',
          isAuthorized: false,
      },
      innerStates: {
          isLoading: true,
          mode: Mode.MONITORING,
          dateTimeRange: false,
      },
      gpsData: {
          engineOnPeriods: [],
          carPoints: [],
          parkingReport: [],
          fuelReport: [],
          carStatisticReport: {},
          carData: {},
          isDetail: false,
      },
      monitoringData: [],
      inputClientData: {
          activeCar: null,
          isDetail: false,
          queryKey: ''
      },
      carList: [],
      errorModalData: {
          isOpen: false,
          message: ''
      },
  },
  getters: {
      getInnerStates(state) {
          return state.innerStates
      },
      getAppMode(state) {
          return state.innerStates.mode
      },
      getActiveCarData(state) {
          return state.monitoringData.find((it) => {
              return it.carId === state.inputClientData.activeCar
          })
      },
      getCarDataForCarId(state) {
          return index => state.monitoringData.find(item => {
              return item.carId === index
          })
      },
      getAdditionalCarDataForCarId(state) {
          return index => state.carList.find(item => {
              return item.carId === index
          })
      },
      getActiveCarAdditionalData(state) {
          let car = state.carList.find((it) => {
              return it.carId === state.inputClientData.activeCar
          })
          if (car) {
              return {
                  plate: car.plate,
                  id: car.carId,
                  model: car.model
              }
          } else {
              return {
                  plate: '',
                  id: '',
                  model: ''
              }
          }
      },
      getInputClientData(state) {
          return state.inputClientData
      },
      getSelectedCars(state) {
          return state.inputClientData.selectedCars
      },
      getPositionData(state){
          return state.positionData
      },
      getPrincipal(state) {
          return state.principal
      },
      getCarPoints(state) {
          return state.gpsData.carPoints
      },
      getParkingReport(state) {
          return state.gpsData.parkingReport
      },
      getFuelReport(state) {
          return state.gpsData.fuelReport
      },
      getCarData(state) {
          return state.gpsData.carData
      },
      getCarList(state) {
          return state.carList
      },
      getMonitoringData(state) {
          return state.monitoringData
      },
      getCarStatisticReport(state) {
          return state.gpsData.carStatisticReport
      },

  },
  mutations: {
      updatePrincipal(state, payload) {
          state.principal = payload
      },
      setSelectedCars(state, payload) {
          state.inputClientData.selectedCars = payload
      },
      updateMonitoringData(state, payload) {
          state.monitoringData = payload.monitoringData
      },
      updateCarList(state, payload) {
          state.carList = payload.carList
      },
      updateGpsData(state, payload) {
          state.gpsData = payload
      },
      resetGpsData(state) {
          state.gpsData = {
              carPoints: [],
              parkingReport: [],
              fuelReport: [],
              carStatisticReport: {},
              carData: {},
              isDetail: false,
          }
      },
      setDateTimePickerMode(state, payload) {
         state.innerStates.dateTimeRange = payload.dateTimeRange
      },
      resetMonitoringData(state) {
          state.monitoringData = []
      },
      closeErrorModal(state) {
          state.errorModalData.isOpen = false
      },
      openErrorModal(state, payload) {
          state.errorModalData.isOpen = true
          state.errorModalData.message = payload
      },
      updateAppMode(state, payload) {
          state.innerStates.mode = payload.mode
      },
      resetInnerStates(state) {
          state.innerStates = {
              isLoading: true,
              mode: Mode.MONITORING,
          }
      },
  },
  actions: {

      switchToMonitoring(context) {
          context.commit('resetGpsData')
          context.commit('resetInnerStates')
          context.commit('resetPartTrackData')
          // context.commit('updateLoader',
          //     {
          //         isLoading: false,
          //     })
      },
      resetUserData(context) {
          context.commit('updatePrincipal',
              {
                  role: '',
                  activeDB: '',
                  userName: '',
                  isAuthorized: false,
              })
          // context.commit('updateLoader',
          //     {
          //         isLoading: false,
          //     })
      },
      loadUserData(context) {
          Vue.axios.get(`/api/getUserData`)
              .then(response => {
                  context.commit('updatePrincipal',
                      {
                          role: response.data.role,
                          activeDB: response.data.activeDB,
                          userName: response.data.userName,
                          startDate: response.data.startDate,
                          finishDate: response.data.finishDate,
                          isAuthorized: true,
                      })
              })
      },
      logout(context) {
          Vue.axios.get('/api/logout')
              .then(response => {
                  context.dispatch('resetUserData')
                  context.commit('resetGpsData')
                  context.commit('resetInnerStates')
                  context.commit('resetMonitoringData')
                  context.commit('resetPartTrackData')
              }).catch(e => {
              alert("Ошибка при выходе")
          })
      },
      loadMonitoringData(context) {
          vueInstance.$f7.preloader.show()
          Vue.axios.get('/api/monitoring', {
              params: {
                  offset: new Date().getTimezoneOffset() / -60
              }
          })
              .then(response => {
                  context.commit('updateMonitoringData',
                      {
                          monitoringData: response.data
                      })
                  vueInstance.$f7.preloader.hide()
              })
      },
      loadCarList(context) {
          Vue.axios.get('/api/cars').then(response => {
              context.commit('updateCarList', {
                  carList: response.data
              })
          })
      },
      loadGpsData(context, params) {
          vueInstance.$f7.preloader.show()
          // context.commit('updateLoader',
          //     {
          //         isLoading: true,
          //     })
          Vue.axios.post('/api/getPoints', {
              carNumber: params.activeCar,
              startDate: params.dateRange[0],
              finishDate: params.dateRange[1],
              offset: params.dateRange[1].getTimezoneOffset() / -60,
              isDetailTrack: false,
          }).then(response => {
              context.commit('updateGpsData', response.data)
              context.commit('updateAppMode', {mode: Modes.TRACK})
              vueInstance.$f7.views.main.router.navigate('/')
              vueInstance.$f7.preloader.hide()
          })
      },
  },
  modules: {
  }
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',[_c('f7-navbar',{attrs:{"title":'№ ' + _vm.title,"back-link":"Back"}}),_c('f7-list',[_c('f7-list-item',{attrs:{"checkbox":"","title":"Ввод времени","name":"timePicker","checked":_vm.isDateTimeRange},on:{"change":_vm.setDatePickerMode}})],1),_c('f7-list',{attrs:{"no-hairlines-md":""}},[_c('f7-list-input',{attrs:{"hidden":_vm.isDateTimeRange,"label":"Период","type":"datepicker","placeholder":"выберете период","calendar-params":{
                    rangePicker: true,
                    header: true,
                    footer: true,
                    openIn: 'customModal',
                    headerPlaceholder: 'выберете период',
                    toolbarCloseText: 'Применить',
                    dateFormat: 'yyyy-mm-dd'
                    }},on:{"calendar:change":_vm.setDateRange}}),_c('f7-list-input',{attrs:{"hidden":!_vm.isDateTimeRange,"id":"startDateTimePicker","label":"Начало периода","type":"datepicker","placeholder":"выберете начало периода","calendar-params":{
                    header: true,
                    footer: true,
                    timePicker: true,
                    openIn: 'customModal',
                    headerPlaceholder: 'выберете начало периода',
                    toolbarCloseText: 'Применить',
                    dateFormat: 'yyyy-mm-dd HH::mm'}},on:{"calendar:change":_vm.setStartDate}}),_c('f7-list-input',{attrs:{"hidden":!_vm.isDateTimeRange,"id":"endDateTimePicker","label":"Конец периода","type":"datepicker","placeholder":"выберете конец периода","calendar-params":{
                    header: true,
                    footer: true,
                    timePicker: true,
                    openIn: 'customModal',
                    headerPlaceholder: 'выберете начало периода',
                    toolbarCloseText: 'Применить',
                    dateFormat: 'yyyy-mm-dd HH::mm'}},on:{"calendar:change":_vm.setEndDate}})],1),_c('f7-row',[_c('f7-col',[_c('f7-button',{attrs:{"fill":""},on:{"click":_vm.getTrack}},[_vm._v("маршрут")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import moment from 'moment'

export default function formatDate(value, isShowYear){
		if (value) {
		    if(isShowYear){
          return moment(String(value)).format('DD.MM.YYYY HH:mm')
        }else{
          return moment(String(value)).format('DD.MM HH:mm')
        }
  		}else{
  			return ''
  		}
}

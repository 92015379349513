import Vue from 'vue'
import App from './App.vue'
import store from './store'

import Framework7 from 'framework7/framework7-lite.esm.bundle.js'
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js'
import 'framework7/css/framework7.bundle.min.css'
// import * as VueGoogleMaps from 'vue2-google-maps'
// import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import axios from 'axios'
import VueAxios from 'vue-axios'
import formatDate from './filters/dateFormatFilter.js'

Vue.config.productionTip = true
Framework7.use(Framework7Vue);
Vue.use(VueAxios, axios)
// Vue.use(VueGoogleMaps, {
//     load: {
//         key: 'AIzaSyAqrY_ZRtIzaY0XwpLl5FEX2v1Zdye0hhY',
//         libraries: 'places',
//     },
// })
// Vue.component('GmapCluster', GmapCluster)

Vue.filter('dateFormatFilter', (value) => {
    return formatDate(value)
})

const vueInstance = new Vue({
    store,
    render: h => h(App)
}).$mount('#app')

axios.interceptors.response.use(
    response => response,
    error => {
        return new Promise((resolve, reject) => {
            if ([401, 403, 404].some(e => e === error.response.status)) {
                store.dispatch('resetUserData')
                vueInstance.$f7.views.main.router.navigate('/login/')
            }
            else if (500 === error.response.status) {
                vueInstance.$f7.toast.create({
                    text: getMessage(error.response.data.message),
                    position: 'center',
                    closeTimeout: 2000,
                }).open()
            } else {
                vueInstance.$f7.toast.create({
                    text: getMessage('Ошибка'),
                    closeTimeout: 2000,
                }).open()
            }
            vueInstance.$f7.preloader.hide()
            reject(error)
        })
    })

function getMessage(code) {

    switch (code) {
        case "100":
            return "Ошибка 100"
        case "101":
            return "Неверный формат имени, пример komtrans_xxxx где x = a-z"
        case "102":
            return "Нет данных по этой машине"
        case "103":
            return "Нет такой машины"
        case "104":
            return "Срок пользования истек"
        case "107":
            return "Пользователь с таким иминем уже существует"
        case "109":
            return "Недоступная операция"
        case "110":
            return "Недоступный период"
        default:
            return "Ошибка на сервере"
    }

}
export {vueInstance}

const ROLES = {
  ROLE_ADMIN: 0,
  ROLE_USER_ADMIN: 1,
  ROLE_USER_EXTERNAL_WITH_FUEL: 2,
  ROLE_USER_EXTERNAL_WITHOUT_FUEL: 3,
  ROLE_USER_INTERNAL_WITH_FUEL: 4,
  ROLE_USER_INTERNAL_WITHOUT_FUEL: 5,
  ROLE_USER: 6,
  ROLE_UNKNOWN: 7
}

const ROLES_OPTIONS = [
  {
    label: 'Администратор',
    isSubUSer: false,
    isHideFuel: false,
    isHideGroupControl: false,
    isHideGroupSelector: false,
    role: ROLES.ROLE_ADMIN
  },
  {
    label: 'Главный пользователь',
    isSubUSer: false,
    isHideFuel: false,
    isHideGroupControl: false,
    isHideGroupSelector: false,
    role: ROLES.ROLE_USER_ADMIN
  },
  {
    label: 'Внешний с топливом',
    isHideGroupControl: true,
    isSubUSer: true,
    isHideFuel: false,
    isHideGroupSelector: true,
    role: ROLES.ROLE_USER_EXTERNAL_WITH_FUEL
  },
  {
    label: 'Внешний без топлива',
    isHideGroupControl: true,
    isSubUSer: true,
    isHideFuel: true,
    isHideGroupSelector: true,
    role: ROLES.ROLE_USER_EXTERNAL_WITHOUT_FUEL
  },
  {
    label: 'Внутренний с топливом',
    isHideGroupControl: true,
    isSubUSer: true,
    isHideFuel: false,
    isHideGroupSelector: true,
    role: ROLES.ROLE_USER_INTERNAL_WITH_FUEL
  },
  {
    label: 'Внутренний без топлива',
    isHideGroupControl: true,
    isSubUSer: true,
    isHideFuel: true,
    isHideGroupSelector: true,
    role: ROLES.ROLE_USER_INTERNAL_WITHOUT_FUEL
  },
  {
    label: 'Обычный пользователь',
    isHideGroupControl: true,
    isSubUSer: true,
    isHideFuel: false,
    isHideGroupSelector: false,
    role: ROLES.ROLE_USER
  },
]
function getRoleOptionsFromRoleName(role){
  let roleOpt =  ROLES_OPTIONS.find((it) => {
    return it.role===ROLES[role]
  })
  if(roleOpt){
    return roleOpt
  }else{
    return{
      label: 'Неизвестная',
      isSubUSer: true,
      isHideFuel: true,
      isHideGroupControl: true,
      role: ROLES.ROLE_UNKNOWN,
    }
  }
}
export {ROLES, ROLES_OPTIONS, getRoleOptionsFromRoleName}

<template>
    <f7-page>
        <f7-navbar :title="title"></f7-navbar>
        <f7-list v-if="isReportExists">
            <f7-list-item header="Начало периода" :title="formatDate(carStatisticReport.startPeriod, true)">
            </f7-list-item>
            <f7-list-item header="Конец периода" :title="formatDate(carStatisticReport.endPeriod, true)">
            </f7-list-item>
            <f7-list-item header="Топливо на начало" :title="carStatisticReport.fuelAtStart + ' Л'">
            </f7-list-item>
            <f7-list-item header="Топлива на окончание" :title="carStatisticReport.fuelAtEnd + ' Л'">
            </f7-list-item>
            <f7-list-item header="Заправлено" :title="carStatisticReport.fuelUpSumm + ' Л'">
            </f7-list-item>
            <f7-list-item header="Слито" :title="carStatisticReport.fuelDownSumm + ' Л'">
            </f7-list-item>
            <f7-list-item header="Расход топлива"
                          :title="carStatisticReport.fuelConsumption<0?'Недоступно':carStatisticReport.fuelConsumption+ ' Л'">
            </f7-list-item>
            <f7-list-item header="Средний расход топлива"
                          :title="formatAverageFuel(carStatisticReport.averageFuelConsumptionOnDistance, 'Л/100 км')">
            </f7-list-item>
            <f7-list-item header="Моточасы" :title="toHoursFilter(carStatisticReport.engineOnDuration)">
            </f7-list-item>
            <f7-list-item header="Колличество стоянок" :title="carStatisticReport.stopsQuantity">
            </f7-list-item>
            <f7-list-item header="Длительность стоянок" :title="toHoursFilter(carStatisticReport.stopsDuration)">
            </f7-list-item>
            <f7-list-item header="Пробег" :title="carStatisticReport.distance.summ.toFixed(1) + ' км'">
            </f7-list-item>
            <f7-list-item header="Пробег по Украине" :title="carStatisticReport.distance.ukraine.toFixed(1) + ' км'">
            </f7-list-item>
            <f7-list-item header="Средняя сокрость" :title="carStatisticReport.averageSpeed + ' км/час'">
            </f7-list-item>
            <f7-list-item header="Максимальная скорость" :title="carStatisticReport.maxSpeed + ' км/час'">
            </f7-list-item>
        </f7-list>
        <!--<f7-list>-->
            <!--<f7-list-item v-for="(fueling, index) in fuelReport"-->
                          <!--:key="'f'+index"-->
                          <!--:header="car.carId"-->
                          <!--:title="car.plate"-->
                          <!--:footer="car.model">-->
            <!--</f7-list-item>-->
        <!--</f7-list>-->
    </f7-page>
</template>

<script>

    import formatDate from '../filters/dateFormatFilter'
    import toHoursFilter from '../filters/minutesToHoursFilter'

    export default {
        name: "Report",
        computed: {
            reportData() {
                return this.$store.getters.getCarData
            },
            carStatisticReport() {
                return this.$store.getters.getCarStatisticReport
            },
            isReportExists(){
                return !!Object.entries(this.reportData).length
            },
            title(){
                if (this.isReportExists) {
                    return '№' + this.reportData.carId + ', ' + this.reportData.plate
                } else {
                    return 'Нет данных'
                }
            }
        },
        methods: {
            formatDate(date, isShowYear) {
                return formatDate(date, isShowYear)
            },
            toHoursFilter(date) {
                return toHoursFilter(date)
            },
            formatAverageFuel(value, prefix) {
                if (isNaN(value) || value > 100 || value === undefined) {
                    return 'Недоступно'
                }
                return value.toFixed(1) + ' ' + prefix
            },
        },
    }
</script>

<style>

</style>
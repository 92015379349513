<template>
    <f7-page>
        <f7-list>
            <f7-list-item v-for="(car, index) in carList " :link="'/track/'+car.carId"
                          :key="index"
                          :header="car.carId"
                          :title="car.plate"
                          :footer="car.model">
                <f7-button round fill slot="after" @click = "routeToMonitoring(car.carId)">
<!--                <f7-icon slot="after" f7="home"></f7-icon>-->
                    И
                </f7-button>
                <f7-badge slot="media" :color="getStatusColor(car.carId)">{{getStatusLabel(car.carId)}}</f7-badge>
<!--                <f7-icon slot="media" icon="demo-list-icon"></f7-icon>-->
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>

    import {detectStatus} from "./CarStateDetector.js";
    import CarStates from './CarStates.js'

    export default {
        name: 'CarList',
        data() {
            return {};
        },
        computed: {
            carList() {
                return this.$store.getters.getCarList
            },
            monitoringCarData() {
                return this.$store.getters.getMonitoringData
            },
        },
        methods: {
            getStatusColor(carId) {
                let car = this.monitoringCarData.find(item => {
                    return item.carId === carId
                })
                if (car) {
                    switch(detectStatus(
                        car.isParking,
                        car.charge,
                        car.date)){
                        case CarStates.NO_CHARGE: return 'red'
                        case CarStates.OUTDATED_MOVING: return 'gray'
                        case CarStates.OUTDATED_PARKING: return 'gray'
                        case CarStates.PARKING: return 'yellow'
                        case CarStates.MOVING: return 'blue'
                    }
                } else {
                    return 'white'
                }

            },
            getStatusLabel(carId) {
                let car = this.monitoringCarData.find(item => {
                    return item.carId === carId
                })
                if (car) {
                    switch (detectStatus(
                        car.isParking,
                        car.charge,
                        car.date)) {
                        case CarStates.NO_CHARGE:
                            return 'Н'
                        case CarStates.OUTDATED_MOVING:
                            return 'Н'
                        case CarStates.OUTDATED_PARKING:
                            return 'Н'
                        case CarStates.PARKING:
                            return 'П'
                        case CarStates.MOVING:
                            return 'М'
                    }
                } else {
                    return ''
                }
            },
            routeToMonitoring(carId) {
              this.$f7router.navigate(`/car/${carId}`)
            }
        }
    }
</script>
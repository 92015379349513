export default function toHours(value){
  if (value) {
    let hours = Math.floor(value/60)
    let minutes = value%60
    if(hours){
      return `${hours} ч ${minutes} мин`
    }
      return `${minutes} мин`

  }else{
    return ''
  }
}

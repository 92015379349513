<template>
    <f7-page :page-content="false" :tabs="true">
        <f7-toolbar tabbar bottom>
            <f7-link tab-link route-tab-id="car-list" href="/car-list" text="Машины"></f7-link>
            <f7-link tab-link route-tab-id="map" href="/" text="Карта"></f7-link>
            <f7-link tab-link route-tab-id="report" href="/report" text="Отчёт"></f7-link>
        </f7-toolbar>
        <f7-tabs routable>
            <f7-tab id="car-list"></f7-tab>
            <f7-tab id="map"></f7-tab>
            <f7-tab id="report"></f7-tab>
        </f7-tabs>
    </f7-page>
</template>

<script>

    const monitoringRefreshRate = 180000

    export default {
        name : 'MainScreen',
        mounted() {
            // this.$store.commit('updateLoader', {isLoading: true})
            this.$store.dispatch('loadUserData').then(() => {
                    this.$store.dispatch('loadMonitoringData')
                    this.monitoringRefresher = setInterval(()=>{
                        this.$store.dispatch('loadMonitoringData')
                    }, monitoringRefreshRate)
                    this.$store.dispatch('loadCarList')
                    // this.$store.commit('updateLoader', {isLoading: false})
                }
            )
        },
    };
</script>


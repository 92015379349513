import * as LeafLet from 'leaflet/dist/leaflet.js'

function computeBounds(markers){

  // debugger
  let lats = markers.map((it)=>{
    return it._latlng.lat
  })

  let lngs = markers.map((it)=>{
    return it._latlng.lng
  })

  let minLat = Math.min(...lats)
  let maxLat = Math.max(...lats)
  let minLng = Math.min(...lngs)
  let maxLng = Math.max(...lngs)
  return L.latLngBounds([minLat, minLng],[maxLat, maxLng])
}

function checkZoom(map) {
  const maxZoom = 12

  if(map.getZoom()>maxZoom){
    map.setZoom(maxZoom)
  }
}
export {computeBounds, checkZoom}

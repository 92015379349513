<template>
    <f7-app :params="f7params">
        <f7-view id="main-view"  main url="/">
        </f7-view>
    </f7-app>
</template>

<script>

    import MainScreen from './components/MainScreen.vue'
    import CarCard from './components/CarCard.vue'
    import TrackRequest from './components/TrackRequest.vue'
    import Login from './components/Login.vue'
    import CarList from './components/CarList.vue'
    import Report from './components/Report.vue'
    import OpenStreetMap from './components/map/oSMap/OpenStreetMap.vue'
    const monitoringRefreshRate = 180000

    export default {
        data() {
            return {
                f7params: {
                    name: 'Regis',
                    theme: 'auto',
                    root: '#app',
                    id: 'com.regis-gps.mobile',
                    routes: [
                        {
                            path: '/',
                            component: MainScreen,
                            tabs: [
                                {
                                    path: '/car-list',
                                    component: CarList,
                                    id: 'car-list'
                                },
                                {
                                    path: '/',
                                    component: OpenStreetMap,
                                    id: 'map'
                                },
                                {
                                    path: '/report',
                                    component: Report,
                                    id: 'report'
                                },

                            ]
                        },
                        {
                            path: '/car/:carId',
                            component: CarCard,
                        },
                        {
                            path: '/track/:carId',
                            component: TrackRequest,
                        },
                        {
                            path: '/login/',
                            component: Login,
                        },
                    ],
                    // ... other params
                }
            }
        },
        destroyed(){
            clearInterval(this.monitoringRefresher)
        }
    }
</script>

<style>
    .fullscreen-block{
        padding: 0 !important;
        margin: 0 !important;
    }
</style>





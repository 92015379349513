<template>
    <f7-page no-toolbar no-navbar no-swipeback login-screen>
            <f7-login-screen-title>Regis-gps</f7-login-screen-title>
            <f7-list form>
                <f7-list-input
                        label="Логин"
                        type="text"
                        placeholder="Ваш логин"
                        :value="username"
                        @input="username = $event.target.value">

                </f7-list-input>
                <f7-list-input
                        label="Пароль"
                        type="password"
                        placeholder="Ваш пароль"
                        :value="password"
                        @input="password = $event.target.value"
                ></f7-list-input>
            </f7-list>
            <f7-list>
                <f7-list-button @click="signIn">Войти</f7-list-button>
                <f7-block-footer class = 'error-message' v-if="authError">Нe верный логин/пароль</f7-block-footer>
            </f7-list>
    </f7-page>
</template>

<script>

    import axios from 'axios'
    import qs from 'qs'

    export default {
        data() {
            return {
                username: '',
                password: '',
                authError: false
            };
        },
        methods: {
            signIn() {
                const app = this.$f7;
                // this.isLoading = true
                let query = qs.stringify({
                    username: this.username,
                    password: this.password
                })
                axios.post('/api/login', query)
                    .then(() => {
                        app.loginScreen.close();
                        this.authError = false
                        this.$store.commit('resetGpsData')
                        // this.$store.commit('resetInnerStates')
                        this.$store.commit('resetMonitoringData')
                        this.$f7router.navigate('/')
                    }).catch(e => {
                    this.authError = true
                })
            },
        },
    };
</script>

<style scoped>
    .error-message {
        color: #ff3b30;
    }
</style>
<template>
  <div>
      <f7-list v-if="isMonitoringMarker">
        <f7-list-item header="№п/п" :title="carData.carId"></f7-list-item>
        <f7-list-item header="Гос номер" :title="carData.plate"></f7-list-item>
        <f7-list-item header="Обновлено" :title="formatDate(carData.date)"></f7-list-item>
        <f7-list-item header="Топливо" :title="carData.fuel + ' Л'" ></f7-list-item>
        <f7-list-item header="Бортсеть" :title="carData.charge + ' В'"></f7-list-item>
        <f7-list-item header="Скорость" :title="carData.speed + ' км/ч'"></f7-list-item>
      </f7-list>
  </div>
<!--    <div v-if="isMonitoringMarker">-->
<!--        <span>№п/п {{carData.carId}}</span>-->
<!--        <span header="Гос номер" >{{carData.plate}}</span>-->
<!--&lt;!&ndash;        <span header="Обновлено" :title="formatDate(carData.date)"></span>&ndash;&gt;-->
<!--&lt;!&ndash;        <span header="Топливо" :title="carData.fuel + ' Л'"></span>&ndash;&gt;-->
<!--&lt;!&ndash;        <span header="Бортсеть" :title="carData.charge + ' В'"></span>&ndash;&gt;-->
<!--    </div>-->
</template>

<script>

  import formatDate from '../../filters/dateFormatFilter.js'
  import toHours from '../../filters/minutesToHoursFilter.js'
  import MarkerTypes from '../MarkerTypes.js'

  export default {
    name: "InfoWindowContent",
    props: {
      windowData: {
        type: Array,
        require: false
      },
      windowType: {
        type: Number,
        require: false
      }
    },

    computed: {
        isMonitoringMarker() {
            return this.windowType === MarkerTypes.MARKER_CAR_MONITORING
        },
        carData() {
            let result = this.$store.getters.getMonitoringData.find((that)=>{
                return that.carId === this.windowData[0]
            })
            if (!result) {
                result = {}
            }
            return result
        }
    },
    methods: {
      formatDate(cellValue) {
        return formatDate(cellValue)
      },
      toHoursFormatter(row, column, cellValue, index) {
        return toHours(cellValue)
      },
      selectItem(it) {
        this.$emit("selectItem", it)
      }
    },
  }
</script>


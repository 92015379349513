const MarkerTypes = {
  MARKER_CAR_MONITORING:1,
  MARKER_PARKING:2,
  MARKER_FUEL_CHANGE:3,
  MARKER_ROAD_DOT:4,
  MARKER_CAR_TRACK:5,
  MARKER_CAR_TRACK_START:6,
  MARKER_CAR_POSITION: 7
}

export default MarkerTypes

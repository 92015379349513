<template>
    <f7-page>
        <f7-navbar :title="'№ ' + title" back-link="Back"></f7-navbar>
        <f7-list>
            <f7-list-item checkbox title="Ввод времени" name="timePicker" :checked="isDateTimeRange"
                          @change="setDatePickerMode"></f7-list-item>
        </f7-list>
        <f7-list no-hairlines-md>
            <f7-list-input
                    :hidden="isDateTimeRange"
                    label="Период"
                    type="datepicker"
                    placeholder="выберете период"
                    @calendar:change="setDateRange"
                    :calendar-params="{
                        rangePicker: true,
                        header: true,
                        footer: true,
                        openIn: 'customModal',
                        headerPlaceholder: 'выберете период',
                        toolbarCloseText: 'Применить',
                        dateFormat: 'yyyy-mm-dd'
                        }"
            ></f7-list-input>
            <f7-list-input
                    :hidden="!isDateTimeRange"
                    id="startDateTimePicker"
                    label="Начало периода"
                    type="datepicker"
                    placeholder="выберете начало периода"
                    @calendar:change="setStartDate"
                    :calendar-params="{
                        header: true,
                        footer: true,
                        timePicker: true,
                        openIn: 'customModal',
                        headerPlaceholder: 'выберете начало периода',
                        toolbarCloseText: 'Применить',
                        dateFormat: 'yyyy-mm-dd HH::mm'}"
            ></f7-list-input>
            <f7-list-input
                    :hidden="!isDateTimeRange"
                    id="endDateTimePicker"
                    label="Конец периода"
                    type="datepicker"
                    placeholder="выберете конец периода"
                    @calendar:change="setEndDate"
                    :calendar-params="{
                        header: true,
                        footer: true,
                        timePicker: true,
                        openIn: 'customModal',
                        headerPlaceholder: 'выберете начало периода',
                        toolbarCloseText: 'Применить',
                        dateFormat: 'yyyy-mm-dd HH::mm'}"
            ></f7-list-input>
        </f7-list>
        <f7-row>
            <f7-col>
                <f7-button fill @click="getTrack">маршрут</f7-button>
            </f7-col>
        </f7-row>
    </f7-page>
</template>

<script>

    import Modes from './Modes'

    export default {
        name: 'TrackRequest',
        props: {
            carId: {
                require: true,
            },
        },
        data() {
            return {
                startDate: null,
                endDate: null
            };
        },
        computed: {
            title() {
                return `${this.extendCarData.carId}, ${this.extendCarData.plate}, ${this.extendCarData.model}`
            },
            extendCarData() {
                return this.$store.getters.getAdditionalCarDataForCarId(parseInt(this.carId))
            },
            monitoringCarData() {
                return this.$store.getters.getCarDataForCarId(parseInt(this.carId))
            },
            isDateTimeRange() {
                return this.$store.getters.getInnerStates.dateTimeRange
            }

        },
        methods: {
            setDatePickerMode(event) {
                this.$store.commit('setDateTimePickerMode', {dateTimeRange: event.target.checked})
                this.dateRange = []
                this.startDate = null
                this.endDate = null
                // todo reset calendars
            },
            getTrack() {
                if (this.startDate && this.endDate) {
                    if (this.startDate.getTime() >= this.endDate.getTime()) {
                        this.$f7.toast.create({
                            text: 'Некорректный период',
                            position: 'center',
                            closeTimeout: 2000,
                        }).open()
                        return
                    }

                    this.$store.dispatch('loadGpsData', {
                        activeCar: parseInt(this.carId),
                        dateRange: [this.startDate, this.endDate]
                    })
                } else {
                    this.$f7.toast.create({
                        text: 'Нужно ввести 2 даты (период)',
                        position: 'center',
                        closeTimeout: 2000,
                    }).open()
                }
            },
            setStartDate() {
                this.startDate = this.$f7.calendar.get().getValue()[0]
            },
            setEndDate() {
                this.endDate = this.$f7.calendar.get().getValue()[0]
            },
            setDateRange() {
                let values = this.$f7.calendar.get().getValue()
                if (values.length === 2) {
                    this.startDate = values[0]
                    this.endDate = new Date(values[1].getTime() + 86399999)
                }
            }
        }
    }
</script>